@import "~simplebar/dist/simplebar.css";
@import "~highlight.js/styles/androidstudio.css";
// Vex Core
@import "./@vex/styles/core";

/*
  You can override any CSS Variable or style here
  Example:

  :root {
   --sidenav-background: #333333;
  }

  All possible variables can be found in @vex/styles/core or by simply inspecting the element you want to change
  in Chrome/Firefox DevTools
*/

/**
  Uncomment the below code and adjust the values to fit your colors, the application will automatically adjust
 */
:root {
  // --color-primary: blue;
  // --color-primary-contrast: white;

  // --color-accent: yellow;
  // --color-accent-contrast: black;

  // --color-warn: yellow;
  // --color-warn-contrast: black;
}

.no-max-width {
  max-width: unset !important;
}

.no-border-radius-top {
  border-top-right-radius: unset !important;
  border-top-left-radius: unset !important;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

.icon {
  color: inherit;
  font-size: 24px;
  width: 24px;
  height: 24px;
}

.mobile {
  display: none !important;
}

@media (min-width: 1280px) {
  .container {
    margin-left: unset !important;
    margin-right: unset !important;
    max-width: 100% !important;
    width: 100% !important;
  }
}
@media screen and (max-width: 960px) {
  .mat-form-field.mobile-menu {
    margin-bottom: 0;

    .mat-form-field-wrapper {
      padding-bottom: 0;

      .mat-form-field-underline {
        bottom: 0 !important;
      }
    }
  }

  div.mat-select-panel {
    min-width: 100% !important;
  }

  .w-12 {
    width: 2rem !important;
  }

  .h-12 {
    height: 2rem !important;
  }

  .p-6 {
    padding: 1rem !important;
  }

  .pb-0 {
    padding-bottom: 0 !important;
  }

  .px-6 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .display-1,
  h1 {
    font-size: 20px;
    line-height: 24px;

    span mat-icon {
      height: 20px;
      height: 20px;
    }
  }

  mat-slide-toggle.lh-36 > .mat-slide-toggle-label {
    height: 36px;
  }

  .desktop {
    display: none !important;
  }

  .no-border-radius {
    border-radius: 0;
    border-bottom-width: 1px;
  }

  .no-border-radius:last-child {
    border-bottom-width: 0;
  }

  .mobile {
    display: unset !important;
  }

  button.mobile span.mat-button-wrapper {
    justify-content: unset;
  }

  button.mobile.mat-flat-button.mat-button-disabled.mat-button-disabled {
    background-color: unset;
  }

  .mat-form-field {
    width: calc(100%);
  }

  .value-caption {
    font-weight: 500;
  }

  .card-content {
    margin-bottom: 0;
  }

  mat-card-header .mat-card-header-text {
    margin: 0 !important;
  }

  mat-card-actions div .flex {
    display: flex;
    flex-wrap: wrap;
  }
}
